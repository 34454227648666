.navigation {
  display: flex;
  flex-direction: column;
  line-height: 29px;
  position: absolute;
  bottom: 30%;
  left: 50px;
  font-size: 1.125rem;
  width: 80px;

  .selected {
    opacity: 1;
  }

  div {
    display: flex;
    align-items: center;
    opacity: 0.5;
  }

  div:not(:last-child) {
    margin-bottom: 20px;
  }

  span {
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 10px;
  }

  a {
    color: #fff;
    text-align: left;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
}
