.header-wrapper {
  width: 100%;
  height: 80px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  position: fixed;
  z-index: 1;
  background-color: #fff;

  @media (min-width: 320px) and (max-width: 767px) {
    width: 100vw;
    height: 60px;
    padding: 1px;
  }

  .header-logo {
    width: 188px;
    height: 47px;
    margin-left: 34px;

    @media (min-width: 320px) and (max-width: 767px) {
      width: 128px;
      height: 31px;
      margin-left: 10px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      margin-left: 20px;
    }
  }

  .header-button {
    // width: 200px;
    width: 20vmin;
    height: 5vh;
    // height: 48px;
    margin-right: 36px;
    border-radius: 5px;
    border: solid 2px #1bc0d4;
    background-color: #ffffff;

    span {
      font-size: 1.6vmin;
      color: #1bc0d4;
    }

    @media (min-width: 320px) and (max-width: 767px) {
      // width: 120px;
      // height: 28px;
      width: 30vmin;
      height: 5vh;
      margin-right: 11px;

      span {
        font-size: 3vmin;
      }
      // font-size: 1rem;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      margin-right: 22px;
      height: 4vh;

      span {
        font-size: 2vmin;
      }
    }
  }
}
