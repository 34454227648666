* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'S-CoreDream-4Regular', 'Noto Sans KR', 'arial', 'sans-serif';
  font-size: 10px;
  line-height: 1;
  width: 100%;
  height: 100%;
  color: #2e3439;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

.flex-container {
  position: relative;
  display: flex;
  flex: 1;
  min-width: 1920px;
  width: 100%;
  height: 100%;
}

.flex-container.h-align-center {
  justify-content: center;
}

.shadow-box {
  box-shadow: 0 2px 3.8px 0.2px rgba(4, 0, 0, 0.1);
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
header,
nav,
footer,
section,
article,
button {
  font-family: 'S-CoreDream-4Regular', 'Noto Sans KR', 'arial', 'sans-serif';
  font-size: 10px;
  margin: 0;
  padding: 0;
  font-weight: normal;
  line-height: 1.1;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset,
img {
  border: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}
ol,
ul {
  list-style: none;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
q:before,
q:after {
  content: '';
}
abbr,
acronym {
  border: 0;
  font-variant: normal;
}
sup {
  vertical-align: text-top;
}
sub {
  vertical-align: text-bottom;
}
input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  outline: none;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(0, 0, 0, 0.3);
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(0, 0, 0, 0.3);
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(0, 0, 0, 0.3);
  }
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  outline: none;
  font-weight: inherit;
}
input,
textarea,
select {
  font-size: 100%;
  outline: none;
}
input[type='text'],
input[type='tel'],
input[type='email'],
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  outline: none;
}
legend,
caption {
  text-indent: -15000px;
  height: 0;
  width: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
label,
button,
input,
input[type='button'],
input[type='submit'],
input[type='reset'] {
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  outline: none;
}

/* clears the 'X' from Internet Explorer */
select::-ms-clear,
select::-ms-reveal,
select::-ms-expand,
input::-ms-clear,
input::-ms-reveal,
input::-ms-check,
input::-ms-expand {
  display: none;
}
button {
  border: none;
  outline: none;
}
a {
  color: #2e3439;
  text-decoration: none;
  outline: none;
}
i {
  font-style: normal;
}
