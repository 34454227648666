.carousel .slide {
  background: transparent;
}

.carousel.carousel-slider .control-arrow {
  display: none;
}

.carousel .carousel-status {
  text-shadow: none;
  font-size: 2rem;
}
