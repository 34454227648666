.footer-wrapper {
  height: 200px;
  background-color: #2b2b2b;
  position: relative;

  a {
    color: #bbbbbb;

    &:visited {
      color: #bbbbbb;
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul {
    display: flex;
    justify-content: center;
    padding-top: 40px;

    li {
      font-size: 1.25rem;
      @media (min-width: 768px) and (max-width: 1024px) {
        margin-right: 95px;
      }
    }

    li:not(:last-child) {
      margin-right: 120px;
      @media (min-width: 768px) and (max-width: 1024px) {
        margin-right: 30px;
      }
    }

    @media (min-width: 320px) and (max-width: 767px) {
      display: none;
    }
  }

  .dropdown {
    z-index: 1;
    position: absolute;
    right: 52px;
    top: 40px;

    @media (min-width: 768px) and (max-width: 1024px) {
      right: 20px;
    }

    &-btn {
      width: 180px;
      height: 40px;
      background-color: transparent;
      border: solid 2px #bbbbbb;
      border-radius: 10px;
      color: #bbbbbb;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 1.25rem;
      }

      img {
        margin: 0 0 1px 18px;
      }

      &.opened {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        width: 144px;
        height: 32px;
      }

      @media (min-width: 320px) and (max-width: 767px) {
        display: none;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      border-left: solid 2px #bbbbbb;
      border-bottom: solid 2px #bbbbbb;
      border-right: solid 2px #bbbbbb;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 10px 16px;
      line-height: 27px;
      font-size: 1.25rem;

      a {
        color: #bbbbbb;
        &:hover {
          color: #ffffff;
        }
        &:visited {
          color: #bbbbbb;
        }
      }
    }
  }

  .mark {
    display: flex;
    justify-content: center;
    margin-top: 87px;

    p {
      color: #bbbbbb !important;
      font-size: 1.5rem;
    }

    @media (min-width: 320px) and (max-width: 767px) {
      display: inline;
      margin-top: 0;

      p {
        font-size: 1rem;
      }
    }
  }
}
