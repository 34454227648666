@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 400;
  src: url('../../fonts/NotoSansKR-Regular.eot') format('eot'),
    url('../../fonts/NotoSansKR-Regular.otf') format('otf'),
    url('../../fonts/NotoSansKR-Regular.ttf') format('ttf'),
    url('../../fonts/NotoSansKR-Regular.woff') format('woff'),
    url('../../fonts/NotoSansKR-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 700;
  src: url('../../fonts/NotoSansKR-Bold.eot') format('eot'),
    url('../../fonts/NotoSansKR-Bold.otf') format('otf'),
    url('../../fonts/NotoSansKR-Bold.ttf') format('ttf'),
    url('../../fonts/NotoSansKR-Bold.woff') format('woff'),
    url('../../fonts/NotoSansKR-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'S-CoreDream-4Regular';
  src: url('../../fonts/SCDream4.otf') format('woff');
}

@font-face {
  font-family: 'S-CoreDream-7ExtraBold';
  font-weight: 700;
  src: url('../../fonts/SCDream7.otf') format('woff');
}

// body {
//   font-family: 'S-CoreDream-4Regular', 'Noto Sans KR', 'arial', 'sans-serif';
//   width: 100%;
//   font-size: 100%;
//   margin: auto;
// }

#root {
  width: 100%;
  height: 100%;
}
