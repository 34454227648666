.main-wrapper {
  overflow-x: hidden;

  .main-intro {
    height: 100%;

    &-empty {
      width: 40%;
      visibility: hidden;
    }

    @media (min-width: 320px) and (max-width: 767px) {
      height: 100vh;
      .empty {
        display: none;
      }
    }

    &-background {
      height: 80vh;
      background-image: url('../../assets/images/files/intro-section-bg.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;

      @media (min-width: 768px) and (max-width: 1024px) {
        background-image: url('../../assets/images/files/intro-section-bg-tablet.jpg');
      }

      @media (min-width: 320px) and (max-width: 767px) {
        background-image: url('../../assets/images/files/intro-section-bg-mobile.jpg');
        position: relative;
        height: 87vh;
      }
    }

    &-main-wrapper {
      width: 90%;
      display: flex;
      margin: auto;
      justify-content: space-around;
      align-items: center;

      @media (min-width: 768px) and (max-width: 1024px) {
        justify-content: flex-start;
      }
    }

    &-content-main-wrapper {
      width: 80%;

      @media (min-width: 768px) and (max-width: 1024px) {
        width: unset;
      }
      @media (min-width: 320px) and (max-width: 565px) {
        width: unset !important;
      }
    }

    &-content-wrapper {
      margin-left: 10%;
      padding-top: 22.498vh;

      @media (min-width: 320px) and (max-width: 565px) {
        margin-left: 0;
        padding-top: 19.498vh;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        margin-left: 0;
      }

      &-main-text {
        font-size: 4.75vmin;
        color: #fff;
        line-height: 7.125vmin;
        text-align: left;

        @media (min-width: 566px) and (max-width: 1024px) {
          width: 500px;
          word-break: keep-all;
          font-size: 6vmin;
          line-height: 8vmin;
        }

        @media (min-width: 320px) and (max-width: 565px) {
          word-break: keep-all;
          font-size: 9vmin;
          line-height: 14vmin;
        }

        span {
          color: #ffea00;
          font-family: 'S-CoreDream-7ExtraBold';
          font-weight: bold;
        }
      }

      &-sub-text {
        font-size: 2.5vmin;
        color: #fff;
        text-align: left;
        margin-top: 3%;

        @media (min-width: 565px) and (max-width: 767px) {
          font-size: 3vmin;
        }

        @media (min-width: 320px) and (max-width: 564px) {
          font-size: 4vmin;
        }

        &-highlight1 {
          color: #1bc0d4;
          font-weight: bold;
          font-family: 'S-CoreDream-7ExtraBold';
        }
        &-highlight2 {
          color: #ff5b7a;
          font-weight: bold;
          font-family: 'S-CoreDream-7ExtraBold';
        }
      }
    }

    &-btn-wrapper {
      width: 36%;
      display: flex;
      justify-content: space-between;
      margin-left: 10%;
      margin-top: 5vh;

      &-img-wrapper {
        width: 45%;
        height: auto;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        width: 80%;
        margin-left: 0;
      }

      @media (min-width: 565px) and (max-width: 767px) {
        width: 50%;
      }

      @media (min-width: 320px) and (max-width: 564px) {
        width: 100%;
        height: 200px;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        margin-left: 15%;
        text-align: center;

        div:nth-child(2) {
          margin-top: 5vmin;
        }

        &-img-wrapper {
          width: 70%;
          img {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }

    &-btn-google {
      width: 100%;
      max-width: 100%;
      height: auto;
    }

    &-btn-app {
      width: 100%;
      max-width: 100%;
    }

    &-scroll-icon-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 13vh;
    }

    &-scroll-icon {
      @media (min-width: 320px) and (max-width: 767px) {
        display: none;
      }
    }

    .main-intro-scroll-icon {
      animation: blink normal 2s infinite ease-in-out;
    }

    @keyframes blink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    &-description {
      height: 22vh;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.875vmin;
      line-height: 7vmin;
      text-align: center;

      @media (min-width: 360px) and (max-width: 767px) {
        height: 13vh;
      }

      &-content-wrapper {
        width: 90%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        div {
          width: 11vmin;

          img {
            width: 100%;
            max-width: 100%;
            height: auto;
          }

          @media (min-width: 360px) and (max-width: 767px) {
            display: none;
          }
        }

        &-text {
          font-size: 2.875vmin;
          line-height: 7vmin;

          @media (min-width: 360px) and (max-width: 767px) {
            font-size: 3vmin;
          }

          @media (min-width: 768px) and (max-width: 1024px) {
            font-size: 2vmin;
            line-height: 5vmin;
          }
          &-highlight {
            color: #1bc0d4;
            font-weight: bold;
            font-family: 'S-CoreDream-7ExtraBold';
          }
        }
      }
    }
  }

  .main-about {
    &-background {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100vh;
      background-image: url('../../assets/images/files/about-section-bg.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;

      @media (min-width: 320px) and (max-width: 767px) {
        height: auto;
        background-image: url('../../assets/images/files/about-section-bg-mobile.jpg');
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        background-image: url('../../assets/images/files/about-section-bg-tablet.jpg');
      }
    }

    .navigation {
      bottom: 100px;
      left: 70px;

      @media (min-width: 768px) and (max-width: 949px) {
        bottom: 100px;
        left: 30px;
      }
      @media (min-width: 950px) and (max-width: 1024px) {
        bottom: 200px;
        left: 30px;
      }
    }

    &-scroll-down {
      display: none;

      @media (min-width: 768px) and (max-width: 1024px) {
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 10px;

        img {
          width: 52px;
          height: 24px;
        }
      }
    }

    &-wrapper {
      width: 90%;
      height: 100vh;
      margin: auto;
      display: flex;
      justify-content: space-around;
      align-items: center;

      @media (min-width: 768px) and (max-width: 1024px) {
        width: 90%;
      }

      @media (min-width: 320px) and (max-width: 767px) {
        align-items: unset;
        height: auto;
        flex-direction: column;
      }

      &-text {
        width: 40%;
        text-align: left;
        color: #fff;

        @media (min-width: 768px) and (max-width: 1024px) {
          width: auto;
        }

        @media (min-width: 320px) and (max-width: 767px) {
          padding-top: 15vh;
          width: 92%;
        }

        &-title {
          font-size: 4.75vmin;

          @media (min-width: 768px) and (max-width: 1024px) {
            font-size: 5vmin;
            width: 380px;
            line-height: 7vmin;
          }

          @media (min-width: 320px) and (max-width: 767px) {
            width: 250px;
            word-break: keep-all;
            font-size: 8.5vmin;
            line-height: 12vmin;
          }

          span {
            color: #ffea00;
            font-family: 'S-CoreDream-7ExtraBold';
          }
        }

        &-content {
          font-size: 2.5vmin;
          margin-top: 4vh;
          line-height: 4.5vmin;

          @media (min-width: 768px) and (max-width: 1024px) {
            font-size: 2.45vmin;
            line-height: 5vmin;
            width: 380px;
            word-break: keep-all;
          }

          @media (min-width: 320px) and (max-width: 767px) {
            margin-top: 6vh;
            font-size: 3.5vmin;
            line-height: 7vmin;
          }

          span {
            font-family: 'S-CoreDream-7ExtraBold';
          }
        }

        &-bottom {
          font-size: 3vmin;
          margin-top: 8vh;
          line-height: 4.5vmin;
          font-weight: bold;

          @media (min-width: 768px) and (max-width: 1024px) {
            font-size: 3.5vmin;
            line-height: 5vmin;
            margin-top: 4vh;
            letter-spacing: -0.54px;
          }

          @media (min-width: 320px) and (max-width: 767px) {
            margin-top: 6vh;
            font-size: 5vmin;
            line-height: 8vmin;
          }
        }
      }
    }

    &-img-container {
      width: 28%;

      @media (min-width: 768px) and (max-width: 1024px) {
        width: 40%;
      }

      @media (min-width: 320px) and (max-width: 767px) {
        width: 100%;
      }

      &-wrapper {
        width: 80%;
        height: auto;
        img {
          max-width: 22vw;
          max-height: 69vh;
        }

        @media (min-width: 320px) and (max-width: 767px) {
          width: 254px;
          height: 397px;
          margin-top: 39px;
          margin-left: auto;
          margin-right: auto;
          background-image: url('../../assets/images/files/mobile-screen-cropped.png');

          img {
            display: none;
          }
        }

        @media (min-width: 768px) and (max-width: 1024px) {
          img {
            max-width: 33vw;
          }
        }
      }
    }
    &-btn-wrapper {
      bottom: 100px;
      position: fixed;
      z-index: 1;
      right: 50px;

      .inactive {
        display: none;

        @media (min-width: 768px) and (max-width: 949px) {
          display: none;
        }
        @media (min-width: 950px) and (max-width: 1024px) {
          display: none;
        }
      }

      &:hover {
        cursor: pointer;
      }

      @media (min-width: 320px) and (max-width: 767px) {
        display: none;
      }

      @media (min-width: 768px) and (max-width: 949px) {
        display: flex;
        position: fixed;
        bottom: 100px;
        right: 30px;
      }

      @media (min-width: 950px) and (max-width: 1024px) {
        display: flex;
        position: fixed;
        bottom: 200px;
        right: 30px;

        img {
          width: 61px;
          height: 61px;
        }
      }
    }
  }

  .main-mobile {
    position: relative;

    .navigation {
      bottom: 100px;
      left: 70px;
    }

    .prev-btn {
      position: absolute;
      background-color: transparent;
      top: 50%;
      left: 60px;
      z-index: 10;

      @media (min-width: 768px) and (max-width: 1024px) {
        display: none;
      }

      @media (min-width: 320px) and (max-width: 767px) {
        display: none;
      }
    }

    .next-btn {
      position: absolute;
      background-color: transparent;
      z-index: 1;
      right: 60px;
      top: 50%;

      @media (min-width: 768px) and (max-width: 1024px) {
        display: none;
      }

      @media (min-width: 320px) and (max-width: 767px) {
        display: none;
      }
    }

    &-background {
      height: 100vh;
      background-image: url('../../assets/images/files/mobile-section-bg.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;

      @media (min-width: 768px) and (max-width: 1024px) {
        background-image: url('../../assets/images/files/mobile-section-bg-tablet.jpg');
      }

      @media (min-width: 320px) and (max-width: 767px) {
        background-image: url('../../assets/images/files/mobile-section-bg-mobile.jpg');
      }
      .scroll-up-btn {
        bottom: 100px;
        position: absolute;
        z-index: 1;
        right: 50px;

        &:hover {
          cursor: pointer;
        }

        @media (min-width: 320px) and (max-width: 767px) {
          display: none;
        }
      }

      .main-mobile-scroll-down {
        display: none;

        @media (min-width: 768px) and (max-width: 1024px) {
          display: inline-block;
          position: absolute;
          bottom: 0;
          width: 100%;
          text-align: center;
          margin-bottom: 30px;
          margin-top: 10px;

          img {
            width: 52px;
            height: 24px;
          }
        }
      }

      .carousel .carousel-status {
        display: none;

        @media (min-width: 320px) and (max-width: 767px) {
          display: block;
          top: 33%;
          left: 4%;
          font-size: 3.5vmin;
          color: #b0efff;
        }

        @media (min-width: 768px) and (max-width: 949px) {
          display: block;
          position: absolute;
          top: 65%;
          left: calc(30% + 168px);
          color: #b0efff;
        }

        @media (min-width: 950px) and (max-width: 1024px) {
          display: block;
          position: absolute;
          top: 65%;
          left: calc(26% + 209px);
          color: #b0efff;
          font-size: 2.5vmin;
        }

        @media (min-width: 1025px) and (max-width: 1336px) {
          left: calc(25% + 486px);
        }
      }

      .carousel .slide {
        background: transparent;
      }
      .carousel .slide img {
        height: 100vh;
      }
    }

    &-main-carousel-wrapper {
      height: 100%;
    }

    &-main-carousel-content {
      text-align: left;
      color: #fff;

      &-title {
        font-size: 3.5vmin;
        margin-bottom: 5vh;

        span {
          margin-left: 5%;
          color: #b0efff;
        }

        @media (min-width: 768px) and (max-width: 1024px) {
          span {
            display: none;
          }
        }

        @media (min-width: 360px) and (max-width: 767px) {
          font-size: 3.5vmin;
          span {
            display: none;
          }
        }
      }

      &-content {
        min-width: 500px;
        font-size: 5.3vmin;
        line-height: 7vmin;

        @media (min-width: 768px) and (max-width: 949px) {
          word-break: keep-all;
          min-width: unset;
        }

        span {
          color: #ffea00;
          font-family: 'S-CoreDream-7ExtraBold';
        }
      }

      @media (min-width: 320px) and (max-width: 767px) {
        width: 264px;
        margin-left: 5%;

        &-title {
          font-size: 1.5rem;
          margin-bottom: 20px;
        }

        &-content {
          width: 260px;
          word-break: keep-all;
          min-width: 210px;
          font-size: 6.5vmin;
          line-height: 9vmin;
        }
      }

      @media (min-width: 950px) and (max-width: 1024px) {
        word-break: keep-all;
        margin-left: 3vw;
      }
    }

    &-sub-carousel {
      display: flex;
      justify-content: space-around;
      width: 80%;
      height: 100vh;
      margin: auto;
      align-items: center;

      .carousel-root {
        width: 400px;
      }

      .carousel .carousel-status {
        display: none;
      }

      .carousel .slide img {
        width: 368px;
        height: 654px;
      }

      .carousel .control-dots {
        bottom: 23px;

        .dot {
          width: 14px;
          height: 14px;
          opacity: 0.5;
          background-color: #00387e;
          box-shadow: unset;
          &.selected {
            background-color: #00387e;
            opacity: 1;
          }
        }
      }

      @media (min-width: 320px) and (max-width: 767px) {
        width: auto;
        height: auto;
        margin-top: 10vh;
        flex-direction: column-reverse;
        justify-content: unset;
        align-items: unset;

        .carousel-root {
          width: 200px;
          margin-top: 10vh;
          align-self: center;
        }

        .carousel .slide img {
          width: 200px;
          height: 355px;
        }

        .carousel .control-dots {
          bottom: 1%;
          .dot {
            width: 8px;
            height: 8px;
          }
        }
      }

      @media (min-width: 768px) and (max-width: 949px) {
        width: 100%;
        justify-content: unset;
      }

      @media (min-width: 950px) and (max-width: 1024px) {
        width: 80%;
        top: 25%;
        left: 5%;
      }

      @media (min-width: 1025px) and (max-width: 1336px) {
        top: 20%;
        left: 8%;
      }
    }
  }

  .main-web-intro {
    position: relative;

    &-empty {
      width: 15%;
      visibility: hidden;
    }

    &-main-wrapper {
      width: 90%;
      height: 100vh;
      display: flex;
      margin: auto;
      justify-content: space-around;
      align-items: center;

      @media (min-width: 768px) and (max-width: 1024px) {
        height: 80vh;
      }

      @media (min-width: 360px) and (max-width: 767px) {
        align-items: unset;
      }
    }

    .scroll-up-btn {
      bottom: 4vh;
      position: absolute;
      z-index: 1;
      right: 50px;

      &:hover {
        cursor: pointer;
      }

      @media (min-width: 320px) and (max-width: 767px) {
        display: none;
      }
    }
    &-wrapper {
      width: 80%;

      @media (min-width: 768px) and (max-width: 1024px) {
        width: 80%;
      }
    }

    &-background {
      height: 100vh;
      background-image: url('../../assets/images/files/web-intro-section-bg.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      color: #fff;

      @media (min-width: 320px) and (max-width: 767px) {
        padding: 0;
        background-image: url('../../assets/images/files/web-intro-section-mobile.jpg');
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        padding: 0;
        background-color: url('../../assets/images/files/web-intro-section-tablet.jpg');
      }
    }

    .navigation {
      bottom: 100px;
      left: 70px;
    }

    &-title {
      font-size: 4.75rem;
      margin-left: 10%;

      span {
        color: #ffea00;
        font-family: 'S-CoreDream-7ExtraBold';
      }

      @media (min-width: 320px) and (max-width: 767px) {
        width: 250px;
        word-break: keep-all;
        margin-bottom: 6vh;
        font-size: 8.5vmin;
        line-height: 12vmin;
        margin-left: 0;
        padding-top: 15vh;
      }
      @media (min-width: 768px) and (max-width: 959px) {
        font-size: 5vmin;
        width: 300px;
        line-height: 7vmin;
        // margin-left: 10%;
        margin-left: 0;
        word-break: keep-all;
      }

      @media (min-width: 950px) and (max-width: 1024px) {
        font-size: 5rem;
        width: 380px;
        word-break: keep-all;
        margin-left: 0;
        line-height: 72px;
      }
    }

    &-content {
      font-size: 2.5vmin;
      line-height: 4.5vh;
      margin-top: 4vh;
      margin-left: 10%;

      @media (min-width: 320px) and (max-width: 767px) {
        width: 143%;
        margin-top: 6vh;
        font-size: 3.5vmin;
        line-height: 7vmin;
        margin: 0;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 2.45vmin;
        line-height: 5vmin;
        margin-left: 0;
        word-break: keep-all;
        width: 100%;
      }
    }

    &-bottom {
      font-size: 3vmin;
      margin-top: 8vh;
      margin-left: 10%;
      line-height: 4.5vmin;
      font-weight: bold;

      .highlight1 {
        color: #1bc0d4;
        font-weight: bold;
        font-family: 'S-CoreDream-7ExtraBold';
      }

      .highlight2 {
        color: #ff5b7a;
        font-weight: bold;
        font-family: 'S-CoreDream-7ExtraBold';
      }

      @media (min-width: 320px) and (max-width: 767px) {
        margin-top: 6vh;
        margin-left: 0;
        font-size: 5vmin;
        line-height: 8vmin;
        width: 143%;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 3.5vmin;
        line-height: 5vmin;
        margin-top: 4vh;
        margin-left: 0;
        letter-spacing: -0.54px;
        width: 100%;
      }
    }
  }

  .main-web {
    position: relative;

    .scroll-up-btn {
      bottom: 100px;
      position: absolute;
      z-index: 1;
      right: 50px;

      &:hover {
        cursor: pointer;
      }

      @media (min-width: 320px) and (max-width: 767px) {
        display: none;
      }
    }

    &-background {
      height: 100vh;

      background-image: url('../../assets/images/files/web-section-bg.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;

      @media (min-width: 768px) and (max-width: 1024px) {
        background-image: url('../../assets/images/files/web-section-bg-tablet.jpg');
      }

      @media (min-width: 320px) and (max-width: 767px) {
        background-image: url('../../assets/images/files/web-section-bg-mobile.jpg');
      }

      .carousel .slide {
        background: transparent;
      }

      .carousel .carousel-status {
        display: none;

        @media (min-width: 768px) and (max-width: 1024px) {
          display: inline;
          color: #b0efff;
          font-size: 2.5vmin;
          top: 28vh;
          left: 5vw;
        }

        @media (min-width: 320px) and (max-width: 767px) {
          display: block;
          top: 40%;
          left: 6%;
          font-size: 3.5vmin;
          color: #b0efff;
        }
      }

      .carousel .slide .web-section-bg {
        height: 100vh;
        visibility: hidden;
      }

      .web-first-img {
        width: 680px;
        height: 407px;
      }

      .web-second-img {
        width: 650px;
        height: 406px;
      }

      .web-third-img {
        width: 650px;
        height: 377px;
      }
    }

    .navigation {
      left: 5vw;
      bottom: 5%;
    }

    &-main-carousel-wrapper {
      height: 100%;
    }

    .prev-btn {
      position: absolute;
      background-color: transparent;
      top: 50%;
      left: 60px;
      z-index: 1;

      @media (min-width: 320px) and (max-width: 767px) {
        display: none;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        display: none;
      }
    }

    .next-btn {
      position: absolute;
      background-color: transparent;
      z-index: 1;
      right: 60px;
      top: 50%;

      @media (min-width: 320px) and (max-width: 767px) {
        display: none;
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        display: none;
      }
    }

    &-main-carousel-content {
      width: 44%;
      text-align: left;
      color: #fff;

      @media (min-width: 768px) and (max-width: 1024px) {
        width: 90%;
      }

      &-title {
        font-size: 2.5vmin;
        margin-bottom: 5vh;

        @media (min-width: 320px) and (max-width: 767px) {
          font-size: 3.5vmin;
          margin-bottom: 3vh;
        }

        @media (min-width: 768px) and (max-width: 1024px) {
          font-size: 3.5vmin;
        }

        span {
          margin-left: 5%;
          color: #b0efff;

          @media (min-width: 320px) and (max-width: 767px) {
            display: none;
          }

          @media (min-width: 768px) and (max-width: 1024px) {
            display: none;
          }
        }
      }

      &-content {
        min-width: 400px;
        font-size: 4.3vmin;
        line-height: 6vmin;

        span {
          color: #ffea00;
          font-family: 'S-CoreDream-7ExtraBold';
        }

        @media (min-width: 320px) and (max-width: 767px) {
          font-size: 2.3rem;
          line-height: 35px;
          width: 255px;
          min-width: 300px;
          height: 140px;
          word-break: keep-all;
        }

        @media (min-width: 768px) and (max-width: 949px) {
          font-size: 3.5rem;
        }

        @media (min-width: 950px) and (max-width: 1024px) {
          font-size: 5.3vmin;
          line-height: 7vmin;
        }
      }
    }

    &-sub-carousel {
      width: 80%;
      height: 100vh;
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: absolute;
      margin-left: 10%;

      &-image {
        width: 40%;

        img {
          width: 100%;
          height: auto;
        }

        @media (min-width: 320px) and (max-width: 767px) {
          width: 87%;
        }

        @media (min-width: 768px) and (max-width: 1024px) {
          width: 80%;
          img {
            margin: 8% 7%;
          }
        }
      }

      .carousel-root {
        width: 400px;
      }

      .carousel .carousel-status {
        display: none;
      }

      .carousel .slide img {
        width: 368px;
        height: 654px;
      }

      .carousel .control-dots {
        bottom: 23px;

        .dot {
          width: 14px;
          height: 14px;
          opacity: 0.5;
          background-color: #00387e;

          &.selected {
            background-color: #00387e;
            opacity: 1;
          }
        }
      }

      @media (min-width: 320px) and (max-width: 767px) {
        width: 100%;
        flex-direction: column;
        align-items: unset;
        margin-left: 7%;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        margin-left: 0;
      }
    }
  }
}
